import "core-js/modules/es.array.splice.js";
import { add_userdata, role_listdata, user_listdata, delete_userlist, edit_list, get_user_infolist } from "../../api/index";
export default {
  name: "basetable",
  data: function data() {
    return {
      //添加菜单数据
      addform: {
        user_login: this.user_login,
        user_pass: this.password,
        user_email: this.user_email,
        user_status: this.user_status,
        role_id_arr: []
      },
      editform: {
        user_login: this.user_login,
        user_pass: this.password,
        user_email: this.user_email,
        user_status: this.user_status,
        role_id_arr: []
      },
      editId: '',
      hoteldatares: {
        page_index: 1,
        page_size: 5
      },
      rules: {
        user_login: [{
          required: true,
          message: "请输入管理员账号",
          trigger: "blur"
        }],
        user_pass: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }],
        user_status: [{
          required: true,
          message: "请选择状态",
          trigger: "change"
        }],
        user_email: [{
          required: true,
          message: "请输入邮箱",
          trigger: "blur"
        }, {
          pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
          message: "请输入正确的邮箱格式，例：***@qq.com或***@163.com",
          trigger: "change"
        }] //    role: [
        //      {
        //        required: true,
        //        message: "请选择角色",
        //        trigger: "blur",
        //      }
        //    ],

      },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      permissionBeans: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData: function getData() {
      var _this = this;

      user_listdata(this.hoteldatares).then(function (res) {
        _this.tableData = res.data.user;
        _this.pageTotal = res.data.count;
      });
      role_listdata().then(function (res) {
        _this.permissionBeans = res.data;
      });
    },
    //创建时间格式化
    timestampToTime: function timestampToTime(row) {
      if (row.last_login_time == 0) {
        return "-";
      } else {
        var date = new Date(row.last_login_time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
        var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) + ':';
        var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) + ' ';
        return Y + M + D + h + m + s;
      }
    },
    // 删除操作
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(function () {
        console.log(row);
        var data = {
          id: row.id
        };
        delete_userlist(data).then(function (res) {
          if (res.code == 1) {
            console.log(res);

            _this2.$message.success("删除成功");

            _this2.tableData.splice(index, 1);

            _this2.getData();
          } else {
            _this2.$message.error(res.msg);
          }
        });
      }).catch(function () {});
    },
    addmenu: function addmenu() {
      this.addVisible = true;
    },
    // 添加保存
    addmenuFun: function addmenuFun(addform) {
      var _this3 = this;

      console.log(this.addform);
      this.$refs[addform].validate(function (valid) {
        if (valid) {
          add_userdata(_this3.addform).then(function (res) {
            if (res.code == 1) {
              _this3.addVisible = false;

              _this3.$message.success(res.msg);

              _this3.getData();
            } else {
              console.log(res);

              _this3.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit: function handleEdit(index, row) {
      var _this4 = this;

      this.idx = index;
      var editdata = {
        id: row.id
      };
      get_user_infolist(editdata).then(function (res) {
        console.log(res);

        if (res.code == 1) {
          _this4.editVisible = true;
          _this4.editform = res.data.user_info;
          _this4.editform.role_id_arr = res.data.role_id_arr;
        }
      });
    },
    addsmall: function addsmall() {
      this.$message.success("\u6DFB\u52A0\u5B50\u83DC\u5355");
    },
    // 保存编辑
    saveEdit: function saveEdit() {
      var _this5 = this;

      console.log(this.editform);
      edit_list(this.editform).then(function (res) {
        if (res.code == 1) {
          _this5.$message.success(res.msg);

          _this5.editVisible = false;

          _this5.getData();
        } else {
          _this5.$message.error(res.msg);
        }
      });
    },
    // 分页导航
    handleCurrentChange: function handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index); //点击第几页

      this.getData();
    }
  }
};